<template>
  <div class="container">
    <div class="lk-my-requests">
      <div class="lk-my-requests__content">
        <div class="table">
          <div class="table__head">
            <div class="table__row">
              <div>Номер заявки</div>
              <div>Название смены</div>
              <div>Дата отправки</div>
              <div>Статус</div>
              <div>Действие</div>
            </div>
          </div>
          <div class="table__body">
            <div
              class="table__row"
              v-for="(item, index) in myRequests"
              :key="index"
            >
              <div data-label="Номер заявки">#{{ item.id }}</div>
              <div data-label="Название смены">{{ item.title }}</div>
              <div data-label="Дата отправки">
                {{ item.date }}
              </div>
              <div
                class="table__cell"
                data-label="Статус"
                :class="[
                  {
                    'table__cell--fail':
                      item.status === 45 || item.status === 40,
                  },
                  {
                    'table__cell--success':
                      item.status === 50 || item.status === 10,
                  },
                  {
                    'table__cell--process':
                      item.status === 28 ||
                      item.status === 27 ||
                      item.status === 0 ||
                      item.status === 35 ||
                      item.status === 37,
                  },
                ]"
              >
                {{ item.status_text }}
              </div>
              <div data-label="Действие">
                <Button
                  red
                  small
                  v-if="isValidDate(item) && isRemoveStatus(item)"
                  @click="openPopup(item.id)"
                >
                  Отозвать заявку
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Button from "@/components/Blocks/Button";
const { mapState } = createNamespacedHelpers("user");
import moment from "moment";
export default {
  name: "LkMyRequests",
  components: { Button },
  data() {
    return {};
  },
  computed: {
    myRequests() {
      return this.user.info_statements?.data.map((item) => {
        return {
          date: item.created_at_format,
          status: item.status,
          title: item.info_shift.title,
          id: item.id,
          status_text: item.status_text,
          shiftId: item.info_shift.id,
          created_year: moment(item.created_at).year(),
        };
      });
    },
    ...mapState(["user"]),
  },
  methods: {
    openPopup(id) {
      this.$store.commit("setCurrentPopup", {
        name: "RollbackPopup",
        isShown: true,
        props: {
          popupClass: "rollback-popup",
          id: id,
        },
      });
    },
    isValidDate(item) {
      return moment().year() === item.created_year;
    },
    isRemoveStatus(item) {
      return item.status !== 35 && item.status !== 40 && item.status !== 45;
    },
  },
  beforeMount() {
    Promise.all([this.$store.dispatch("user/getUserInfo")]);
  },
};
</script>

<style lang="scss">
.lk-my-requests {
  .table__row {
    grid-template-columns: 15% 35% 15% 20% 15%;
  }
}
</style>
